import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Forms from "../../Landing/Form/Forms";
// import {PaymentElement,useStripe,useElements} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { paymentModeDataMethod } from "../../../containers/app/features/CounterSlice";
import { getName, landingFormsData } from "../../../constants/Utils";
import { addMoneyWalletApi } from "../../../services/ProfilePageServices";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

export default function CheckoutForm({
  cartValue,
  globalCheckoutDetails,
  isWallet,
  payAmount,
  handleClose,
  timeSlotFlag,
}) {
  const [showForms, setShowForms] = useState(false);
  const closeFormsPopUp = (value) => {
    setShowForms(value);
  };
  const stripe = useStripe();
  const elements = useElements();
  let userName = getName() ? getName()[0] : null;
  const [guestUserFlag, setGuestUserFlag] = useState(userName === "Guest");
  const [isDisable, setIsDisable] = useState(false);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState();
  const [paymentMode, setPaymentMode] = useState("1");
  const dispatch = useDispatch();

  let navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          let paymentObject = {
            paymentId: paymentIntent?.id,
            paymentStatus: paymentIntent?.status,
          };
          sessionStorage.setItem(
            "IntentPaymentId",
            JSON.stringify(paymentObject)
          );
          sessionStorage.setItem("PaymentMsg", JSON.stringify("success_01"));
          navigate("/process_payment");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
    if (cartValue && globalCheckoutDetails) {
      if (
        parseInt(globalCheckoutDetails.item_total) <
        parseInt(cartValue.min_order_value)
      ) {
        setIsDisable(true);
        setMessage(
          `Minimum order value should be $ ${cartValue.min_order_value}`
        );
      } else {
        setMessage("");
        setIsDisable(false);
      }
    }
  }, [cartValue, globalCheckoutDetails]);

  const handleSubmit = async (e) => {
    if (isWallet) {
      // Wallet Payment
      e.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
      if (sessionStorage.getItem("paymentMode") === "1") {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: window.location.origin + "/process_payment",
          },
        });

        if (
          error &&
          (error.type === "card_error" || error.type === "validation_error")
        ) {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      } else if (sessionStorage.getItem("paymentMode") === "2") {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: window.location.origin + "/process_payment",
          },
          redirect: "always",
        });

        if (
          error &&
          (error.type === "card_error" || error.type === "validation_error")
        ) {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      }
      setIsLoading(false);
    } else {
      // Card / PayNow
      if (landingFormsData().user_type === 2) {
        e.preventDefault();
        setShowForms(true);
      } else {
        e.preventDefault();

        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
        setIsLoading(true);
        if (sessionStorage.getItem("paymentMode") === "1") {
          // Card Payment
          const { paymentIntent, error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.origin + "/payment",
            },
            redirect: "if_required",
          });
          if (error) {
           
            if (
              error &&
              (error.type === "card_error" || error.type === "validation_error")
            ) {
              setMessage(error.message);
            } else {
              setMessage("An unexpected error occured!.");
            }
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            let paymentObject = {
              paymentId: paymentIntent?.id,
              paymentStatus: paymentIntent?.status,
            };
            sessionStorage.setItem(
              "IntentPaymentId",
              JSON.stringify(paymentObject)
            );
            navigate("/process_payment");
          }
        } else if (sessionStorage.getItem("paymentMode") === "2") {
          // PayNow
         
          const { paymentIntent, error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.origin + "/payment",
            },
            redirect: "if_required",
          });
         
          if (error) {
           
            if (
              error &&
              (error.type === "card_error" || error.type === "validation_error")
            ) {
              setMessage(error.message);
            } else {
              setMessage("An unexpected error occured!.");
            }
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            let paymentObject = {
              paymentId: paymentIntent?.id,
              paymentStatus: paymentIntent?.status,
            };
            sessionStorage.setItem(
              "IntentPaymentId",
              JSON.stringify(paymentObject)
            );
            navigate("/process_payment");
          }
          // if (
          //   error &&
          //   (error.type === "card_error" || error.type === "validation_error")
          // ) {
          //   setMessage(error.message);
          // } else {
          //   setMessage("An unexpected error occured!.");
          // }
        }
        setIsLoading(false);
      }
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const handlePaymentOnChange = (e) => {
    setType(e.value?.type);
    if (e.value?.type === "card") {
      setPaymentMode("1");
      sessionStorage.setItem("paymentMode", "1");
    } else if (e.value?.type === "paynow") {
      setPaymentMode("2");
      sessionStorage.setItem("paymentMode", "2");
    } else {
      console.log("error");
    }
  };

  return (
    <>
      <Forms showForms={showForms} closeFormsPopUp={closeFormsPopUp} />
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement
          id="payment-element"
          options={paymentElementOptions}
          onChange={handlePaymentOnChange}
        />

        <button
          className="payNowButtonPayment"
          disabled={
            isLoading || !stripe || !elements || isDisable || guestUserFlag
            // timeSlotFlag
          }
          id="submit"
        >
          {isWallet ? (
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                `Add $${payAmount}`
              )}
            </span>
          ) : (
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                `Pay now $${payAmount}`
              )}
            </span>
          )}
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
}
