import { React, useState, useEffect } from "react";
import { Col, Row, Button, Card, Badge } from "react-bootstrap";
import {
  walletDetailsApi,
  filterWalletApi,
} from "../../../services/ProfilePageServices";
import FilterWalletPopup from "../Popup/Wallet/FilterWalletPopup";
import { AiFillFilter } from "react-icons/ai";
import AddMoneyPopup from "../Popup/Wallet/AddMoneyPopup";
import "./Wallets.css";
function Wallets() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const closePopUp = (value) => {
    setShow(value);
  };

  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const showshowFilter = () => {
    setShowFilterPopup(true);
  };

  const LocationPopUp = (value) => {
    setShowFilterPopup(value);
  };

 
  const [walletData, setWalletData] = useState([]);
  const [walletDataAll, setWalletDataAll] = useState([]);

  const walletDetailsApiValuesApi = async () => {
    let postWalletDetailsApiObject = {
      page: "0",
      limit: "100",
    };

    try {
      let walletDetailsApiResponse = await walletDetailsApi(
        postWalletDetailsApiObject
      );
      setWalletData(walletDetailsApiResponse.data.data);
      setWalletDataAll(walletDetailsApiResponse.data.data?.all_transactions);
      // window.location.reload(true); //refresh the page
    } catch (e) {}
  };

  useEffect(() => {
    walletDetailsApiValuesApi();
  }, []);

  const handleFilterData = (data) => {
    setWalletDataAll(data);
  };

  const refreshData = () => {
    walletDetailsApiValuesApi();
  };

  return (
    <>
      <div>
        <div className="title-container">
          <h2>Wallet Detail</h2>
        </div>
        <div className="total-bal-container">
          <div className="total-bal">
            <b>Total balance</b>
            <b className="green-text">S${walletData?.total_balance}</b>
          </div>
          <div className="bal-content">
            <div>
              <p>Total Money Added</p>
              <b> S${walletData?.total_money_added}</b>
            </div>
            <div>
              <p>Total Cashback</p>
              <b>S${walletData?.total_cashback}</b>
            </div>
          </div>
        </div>
        <div>
          <div className="transaction-header-container">
            <div className="transaction-title">
              <b>Transactions</b>
            </div>
            <div className="transaction-icon">
              <AiFillFilter onClick={showshowFilter} />
            </div>
          </div>
          <div className="transaction-list">
            {walletDataAll?.map((item, index) => (
              <div className="transaction-content">
                <div>
                  <p className="RestNameWalletDetails">
                    {item.display_name} (
                    {item.type === "3"
                      ? "Debited"
                      : item.type === "2"
                      ? "Money Added"
                      : "Cashback"}
                    )
                    <br/>
                    
                  {item.wallet_date}</p>
                </div>
                <div className="green-text">
                  <b>{item.credited>0?"S$"+item.credited:<small className="walletDebitedProfile">S$-{item.debited}</small>}</b>
                </div>
              </div>
            ))}
          </div>
          <div className="add-money-container">
            <Button className="add-money-btn" onClick={handleShow}>
              Add Money
            </Button>
          </div>
        </div>
      </div>

      <AddMoneyPopup
        show={show}
        closePopUp={closePopUp}
        refreshData={refreshData}
      />
      <FilterWalletPopup
        showFilterPopup={showFilterPopup}
        LocationPopUp={LocationPopUp}
        handleFilterData={handleFilterData}
      />
    </>
  );
}

export default Wallets;
