import { React, useEffect, useState } from "react";
import { Col, Row, Button, Card } from "react-bootstrap";
import Location from "../../Landing/Location/Location";
import { AddressVals } from "../../../constants/HomePageResponse";
import "./AddressView.css";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import {
  deliverLatLngData,
  deliveryAddressData,
  getName,
  sessionLocationData,
} from "../../../constants/Utils";
import Forms from "../../Landing/Form/Forms";
import { getUserType } from "../../../constants/Utils";
import CheckMark from "../../../Asserts/PaymentandLogin/Checkmark.png";
function AddressView({ addressdata }) {
  let user = JSON.parse(sessionStorage.getItem("otpResponse"));
 
  const [showForms, setShowForms] = useState(false);
  const closeFormsPopUp = (value) => {
    setShowForms(value);
  };
  const handleShowForms = () => {
    setShowForms(true);
  };
  let navigate = useNavigate();
  const navigateToProfile = () => {
    if (getUserType() === 2) {
      handleShowForms();
    } else {
      navigate("/profile", { state: { addressView: true } });
    }
  };
  const [showLocation, setShowLocation] = useState(false);
  const showLocationPopup = () => setShowLocation(true);

  const LocationPopUp = (value) => {
    setShowLocation(value);
  };
  const [pinState, setPinState] = useState();
  useEffect(() => {
    let pinAddress = deliverLatLngData()?.pin_address;
    setPinState(pinAddress);
    setTimeout(() => {
      deliverLatLngData();
    }, 2000);
  }, [pinState]);
  let lableType = deliverLatLngData()?.label_type
    ? deliverLatLngData()?.label_type
    : JSON.parse(sessionStorage.getItem("otpResponse"))?.address_type;

  return (
    <>
      {window.location.href.includes("/payment") && user?.user_type === 2 ? (
        <>
          <div className="LoginAccountPayment">
            <div>
              <h4>
                Account
                <img
                  src={CheckMark}
                  alt=""
                  className="checkmarkpaymentLogin ms-4"
                />
              </h4>
            </div>
            <div>
              <p>To Place an order now, please login to continue or sign up</p>
            </div>
            <div>
              <Button className="Login_Or_Sign mb-1" onClick={handleShowForms}>
                <small className="have_an_accoubt">Have an Account?</small>
                <br />
                <small className="login_signup">LOG IN or SIGN UP</small>
              </Button>
            </div>
          </div>
        </>
      ) : null}
      <div className="DeliveryAddressPayment">
        <p>Delivery Address</p>
      </div>

      <div className="AddressDetailsContainer">
        <div className="CurruntAddresspayment">
          {/* <div className="locationIconpayment"> */}
          <p>
            <GrLocation />
            <small className="ms-3">
              {lableType == "1"
                ? "Home"
                : lableType == "2"
                ? "Office"
                : "Others"}
            </small>
            <small className="DeliveryAddressPaymentPage ">
              <span className="addressDataPayment">Address :</span>
              <small>
                {deliverLatLngData()?.unit_number
                  ? deliverLatLngData()?.unit_number !== ""
                    ? deliverLatLngData()?.unit_number + ","
                    : null
                  : sessionLocationData()?.unit_number !== ""
                  ? sessionLocationData()?.unit_number + ","
                  : null}
                {deliverLatLngData()?.street_address
                  ? deliverLatLngData()?.street_address !== ""
                    ? deliverLatLngData()?.street_address + ","
                    : null
                  : sessionLocationData()?.street_address !== ""
                  ? sessionLocationData()?.street_address + ","
                  : null}
                {deliverLatLngData()?.pin_address
                  ? deliverLatLngData()?.pin_address + ","
                  : sessionLocationData()?.pin_address + ","}
                {deliverLatLngData()?.postal_code
                  ? deliverLatLngData()?.postal_code + ","
                  : sessionLocationData()?.postal_code + ","}
              </small>
            </small>
          </p>
          {/* </div> */}
        </div>
        <div>
          <Card className="addAddressPaymentCard">
            <div className="addAddressPayment">
              <b>
                <MdOutlineAddLocationAlt className="addNewIconPayment" />
                Add New Address
              </b>
            </div>
            <div className="Add_newAddressPayment ">
              <Button
                className="addNewAddressButtonPayment"
                onClick={navigateToProfile}
              >
                ADD NEW
              </Button>
            </div>
          </Card>
        </div>
      </div>

      <Location />

      <Row>
        <Col lg="1" />
        <Col lg="10">
          <hr className="mt-5 mb-5 hr " />
        </Col>
      </Row>
      <Location showLocation={showLocation} LocationPopUp={LocationPopUp} />
      <Forms showForms={showForms} closeFormsPopUp={closeFormsPopUp} />
    </>
  );
}
// const mapStateToProps = state => {
//   return {
//     addressdata:state.addressdata,
//  }
// }
// connect(mapStateToProps,null)
export default AddressView;
