import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";
import Instagram from "../../Asserts/social_media_icons/instagram.png";
import facebook from "../../Asserts/social_media_icons/facebook.png";
import twitter from "../../Asserts/social_media_icons/twitter.png";
import desi from "../../Asserts/Frame 37 (1).png";
import PlayStore from "../../Asserts/social_media_icons/Google-play.png";
import AppStore from "../../Asserts/social_media_icons/apple-store.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Location from "../Landing/Location/Location";
import "../Footer/footer.css";
function Footer() {
  let navigate = useNavigate();
  const restaurantLocation = useLocation();
  const [showLocation, setShowLocation] = useState(false); //Location modal
  const LocationPopUp = (value) => {
    setShowLocation(value);
  };
  const navigateHome = () => {
    if (
      JSON.parse(sessionStorage.getItem("isDirectLanding")) == 1 &&
      Object.keys(JSON.parse(sessionStorage.getItem("userLocation"))).length ==
        0
    ) {
      setShowLocation(true);
    } else if (
      restaurantLocation?.pathname !== "/" &&
      restaurantLocation?.pathname !== "/home"
    ) {
      navigate("/home");
      window.scrollTo(0, 0);
    }
  };

  const handleAndroidAppNavigation = () => {
    let url =
      "https://play.google.com/store/apps/details?id=com.onedigital.desieats";
    window.open(url, "_blank").focus();
  };

  const handleIosAppNavigation = () => {
    let url = "https://apps.apple.com/in/app/desi-eats/id1626964947";
    window.open(url, "_blank").focus();
  };

  // window.initHippo({
  //   appSecretKey: "804f8e693c07559e9a03db0721f711e2",
  //   hideNewConversation: '1',
  //   alwaysSkipBot: true,
  //   language: 'en',
  //   color: "#FF6838",
  //   email: getUserData()?.email ,
  //   name: userName,
  //   phone: getUserData()?.mobile,
  //   customAttributes: {
  //      'appId':'2',
  //     },
  // });

 
  const location = useLocation();
  // if(location.pathname!=="/"&& getUserType()!==2){
  //   window.initHippo({
  //     appSecretKey: "804f8e693c07559e9a03db0721f711e2",
  //     hideNewConversation: "1",
  //     alwaysSkipBot: true,
  //     language: "en",
  //     color: "#FF6838",
  //     email: getUserData()?.email,
  //     name: getUserData()?.fullname,
  //     phone: getUserData()?.mobile,
  //     customAttributes: {
  //       appId: "2",
  //     },
  //   });
  // }
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  useEffect(() => {
    setIsMobile(window.innerWidth);
  }, []);
  let RestUrl = sessionStorage.getItem("identifier_key")
    ? sessionStorage.getItem("identifier_key")
    : null;

  return (
    <>
      <Row className="footerSection mx-5 mt-5">
        <ScrollToTop className="one" smooth />
        <div className="footer-box">
          <div className="style5" />
          <div className="footer-container">
            <div className="footer-content ">
              <img className="desi-img" src={desi} alt=""></img>
              <p className="desiEats_Description">
                Desi eats is specially catered to delivery Desi Food in
                island-wide Singapore. Are you a fan of authentic Desi spices?
                think no more Desi eats is here to satisfy your cravings.
              </p>
              <div className="sclMdaIcon">
                <img
                  src={facebook}
                  width="12%"
                  className="FaceBookFooter"
                  alt=""
                ></img>
                <img
                  src={Instagram}
                  width="12%"
                  className="InstagramFooter"
                  alt=""
                ></img>
                <img
                  src={twitter}
                  width="12%"
                  className="TwitterFooter"
                  alt=""
                ></img>
              </div>
            </div>
            <div className="footer-content extra">
              <h3 className="useful ">Useful Links</h3>
              <div>
                <small
                  className="hometextinfooter"
                  onClick={() => navigateHome()}
                >
                  Home
                </small>
              </div>
              <div>
                <small className="hometextinfooter">About us</small>
              </div>
            </div>
            <div
              className={
                location.pathname === `/restaurants/${RestUrl}` ||
                setIsMobile < 800 ||
                location.pathname === "/payment"
                  ? "footer-content ProductHelp-Left-restpage "
                  : "footer-content extra"
              }
            >
              <h3 className="useful">Product Help</h3>
              <div>
                <small className="hometextinfooter">Contact</small>
              </div>
            </div>
            <div
              className={
                location.pathname === `/restaurants/${RestUrl}` ||
                setIsMobile < 800 ||
                location.pathname === "/payment"
                  ? "footer-content ProductHelp-Left-restpage"
                  : "footer-content extra "
              }
            >
              <h3 className="useful download-text">Download</h3>
              <div className="apStreIcon">
                <div>
                  <img
                    className={
                      location.pathname === `/restaurants/${RestUrl}` ||
                      setIsMobile < 800 ||
                      location.pathname === "/payment"
                        ? " playStoreAppStoreIcon "
                        : "media_icon"
                    }
                    src={PlayStore}
                    alt=""
                    onClick={() => handleAndroidAppNavigation()}
                  ></img>
                </div>
                <div>
                  <img
                    className={
                      location.pathname === `/restaurants/${RestUrl}` ||
                      setIsMobile < 800 ||
                      location.pathname === "/payment"
                        ? "appStoreAppStoreIcon"
                        : " AppStoreIcon"
                    }
                    src={AppStore}
                    alt=""
                    onClick={() => handleIosAppNavigation()}
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="style5" />
          <div className="cpyRtCntnr">
            <p className="mt-4">&#169; Copyrights 2023 All Rights Reserved</p>
          </div>
        </div>
      </Row>
      <Location showLocation={showLocation} LocationPopUp={LocationPopUp} />
    </>
  );
}

export default Footer;
