import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Routers from "../../routes/Routers.js";
import Footer from "../../components/Footer/Footer.jsx";
import { Provider } from "react-redux";
import { store } from "./store";
import axios from "axios";
import "./App.css";
const App = () => {
  const [isLoader, setIsLoader] = useState(false);
  // Add a request interceptor
  axios.interceptors.request.use(function (config) {
    if (
      !config.url.includes(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng"
      )
    ) {
      let user = JSON.parse(sessionStorage.getItem("otpResponse"));
      config.headers.Authorization = user?.token?user?.token:"";
      return config;
    }
    return config;
  });

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      setIsLoader(true);
      return config;
    },
    function (error) {
      setIsLoader(false);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      setIsLoader(false);
      return response;
    },
    function (error) {
      setIsLoader(false);
      return Promise.reject(error);
    }
  );
  return (
    <>
      <Provider store={store}>
        {/* {isLoader ? (
          <div className="loader-container">
            <center className="loder-position">
              <div class="loader" id="loader"></div>
              <p class="loading-text">LOADING...</p>
              <br></br>
            </center>
          </div>
        ) : null} */}
        <div>
          <Routers />
        </div>

        <div>
          <Footer />
        </div>
        <div className="App"></div>
      </Provider>
    </>
  );
};

export default App;
