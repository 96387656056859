import React from "react";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import swal from "sweetalert";

function DeleteAddressPopUp({
  deleteAddress,
  DeleteAddressPopUpData,
  handleDelete,
  addressDatasVals,
}) {
  const CloseDeleteAccountPopUp = () => {
    DeleteAddressPopUpData(false); //callback function
  };
  let uIaddressId = addressDatasVals ? addressDatasVals : null;
  let headeraddressId = sessionStorage.getItem("deliverLatLng")
    ? JSON.parse(sessionStorage.getItem("deliverLatLng"))
    : sessionStorage.getItem("userLocation")
    ?  JSON.parse(sessionStorage.getItem("userLocation"))
    : null;
   

  const deleteAdd = () => {
  
    if ((uIaddressId?.id == headeraddressId?.id) && (uIaddressId?.pin_address==headeraddressId?.pin_address)) {
      swal({
        title: "Error!",
        text: "Can't Delete This Address!",
        type: "Error",
        timer: 2500,
        button: false,
        icon: "error",
        className: "popuptetx",
      });


    } else {
      handleDelete();
    }
  };
  return (
    <>
      <Modal
        className="deleteaccountPopupProfileModal .modal-lg"
        show={deleteAddress}
        onHide={CloseDeleteAccountPopUp}
        animation={true}
      >
        <Row>
          <Modal.Header className=" deleteAddressPopupclose" closeButton>
            {/* <Col lg="12" className="DeleteAccountPopup mt-5 mb-3" ><small  className="DeleteAccountText ">
          Delete Account
            </small></Col> */}
          </Modal.Header>
        </Row>

        <Row>
          <Col lg="12">
            <p className="deleteaccountText mb-3">
              Are You Sure? Do You Want to Delete this Address
            </p>
          </Col>
        </Row>

        <Row className="mt-3 mb-5">
          <Col lg="3" />
          <Col lg="3" sm="3" md="3"xs="3">
            <Button
              className="cancelaccountpopupProfile"
              onClick={() => CloseDeleteAccountPopUp()}
            >
              Cancel
            </Button>
          </Col>
          <Col lg="3"sm="3" md="3"xs="3">
            <Button
              className="deleteaccountpopupProfile"
              onClick={() => deleteAdd()}
            >
              Ok
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default DeleteAddressPopUp;
