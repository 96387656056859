import { React, useState, useEffect } from "react";
import { Col, Row, Button, Card, Badge } from "react-bootstrap";
import "./Favourite.css";
import RatingStart from "../../../Asserts/RestaurentList/RatingStar.png";

import { GrStar } from "react-icons/gr";
import { WhishListGetApi } from "../../../services/ProfilePageServices";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { WhishListApi } from "../../../services/ProfilePageServices";
import { whishListPostApi } from "../../../services/HomePageServices";
import { useNavigate } from "react-router-dom";

import {
  deliverLatLngData,
  selfPickUpValue,
  sessionLocationData,
  singaporeTimeConverter,
} from "../../../constants/Utils";

function Favourite() {
  const [clickedItems, setClickedItems] = useState([]);
  let navigate = useNavigate();
  const params = new URLSearchParams();

  const ShowRestaurantData = (item) => {
    sessionStorage.setItem("restaurantData", item.restaurant_id);
    sessionStorage.setItem("identifier_key", item.rest_identifier);
    let RestUrl = sessionStorage.getItem("identifier_key")
      ? sessionStorage.getItem("identifier_key")
      : null;

    navigate({
      pathname: `/restaurants/${RestUrl}`,
      search: params.toString(), // '?name=John&age=32
    });
  };
  const HandleClick = (item) => {
    //setRestaurent(item)
   
    // setIsSelected(item.id)
    //   if (favItems.indexOf(item.id) === -1) {
    //     favItems.push(item.id);
    //   } else {
    //     favItems.splice(index, 1);
    //   }
  

    setClickedItems((prevState) => {
      if (prevState.includes(item.restaurant_id)) {
        return prevState.filter((value) => value !== item.restaurant_id);
      }
      return [...prevState, item.restaurant_id];
    });

    
  };

  const [wishListData, setWishListData] = useState([]);

  const wishListGetValuesApi = async () => {
    let postWishListObject = {
      page: "0",
      limit: "100",
      date_timestamp: singaporeTimeConverter(),
    };

    try {
      let WishListResponse = await WhishListGetApi(postWishListObject);
      setWishListData(WishListResponse.data.data);
     
      // window.location.reload(true); //refresh the page
    } catch (e) {}
  };

  useEffect(() => {
    wishListGetValuesApi();
  }, []);

  const handleFavourites = async (isfavourite, item) => {
    let postData = {
      restaurant_id: item.restaurant_id,
      action_type: isfavourite,
    };
    let wishListResp = await whishListPostApi(postData);
    if (wishListResp) {
      wishListGetValuesApi();
    }
  };

  return (
    <>
      <div className="fvrtPgWrpr">
        <h3 className="favPgHdr">Favourite</h3>
        <div className="fvPgTgln">
          <p className="mb-0">Your Favourite Restaurant</p>
        </div>
        <div className="favPgScrlbr">
          {wishListData.length > 0
            ? wishListData.map((item, index) => (
                <>
                  <Card
                    className="TrendingCardHomepage d-flex align-items-stretch"
                    onClick={() => {
                      ShowRestaurantData(item);
                    }}
                  >
                    <img
                      src={item.banner_image}
                      alt=""
                      className="
   trendingImage 
   img-responsive img-portfolio img-hover
   img-fluid "
                    />
                    {item.min_order_value > 0 ? (
                      <Badge
                        className="minimum_value_trending mb-3"
                        variant="outlined"
                      >
                        Minimum Order Value: S${item.min_order_value}
                      </Badge>
                    ) : null}

                    {item.delivery_handled_by === "1" ? (
                      <Badge
                        className="DeliveryHandledByTrending mb-3"
                        variant="outlined"
                      >
                        <marquee> Delivery Handled By {item.rest_name}</marquee>
                      </Badge>
                    ) : null}
                    {item.is_order_later_accept == "1" &&
                    item.restro_is_open != "1" ? (
                      <Badge className="PreOrderLable">Preorder</Badge>
                    ) : null}
                    <Row
                      className={
                        item.restro_is_open != "1" ? "RestCloseDiv" : ""
                      }
                    >
                      {/* inside card splitting size 8-4 */}
                      <Col lg="8" md="8" sm="8" xs="8">
                        <div className="ms-2 mt-2 ">
                          <p className=" Restaurent_Title"> {item.rest_name}</p>
                          {/* <br /> */}
                          {item.next_open_time !== "" ? (
                            <Badge className="openBackTimeBadge">
                              <small className="OpenBackTime">
                                {item.next_open_time !== ""
                                  ? "Opening Back" + " " + item.next_open_time
                                  : null}
                              </small>
                            </Badge>
                          ) : null}

                          <p className="mb-2 Restaurent_SubTitle text-muted">
                            {item.res_description}
                          </p>
                        </div>

                        <Badge
                          className="delivery_by mt-2 mb-5"
                          variant="outlined"
                        >
                          {item.Delivery_by}
                        </Badge>
                      </Col>
                      <Col lg="4" md="4" sm="4" xs="4">
                        {item.restro_is_open === "1" ? (
                          <Badge className="restOpenTrending Restaurent_Open_Close  mt-2">
                            <small>Open</small>
                          </Badge>
                        ) : (
                          <Badge className="Restaurent_Close_home ">
                            <small>Closed</small>
                          </Badge>
                        )}
                        <br />
                        <small>
                          <img
                            src={RatingStart}
                            alt=""
                            className="Restaurent_Rating_star RatingStarTrending mx-1 pb-1"
                          />
                          <small className="RatingCountItemsRestaurant">
                            {item.avg_rating}/5
                          </small>
                        </small>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="7" xs="8" md="6" sm="7">
                        {item.open_time !== "" || item.close_time !== "" ? (
                          <Badge
                            className="open_hours_badge openHoursTrending  ms-2"
                            variant="outlined"
                          >
                            {"Open Hours-" +
                              item.open_time +
                              "-" +
                              item.close_time}
                          </Badge>
                        ) : null}
                      </Col>
                      <Col lg="4" md="4" xs="4" sm="4">
                        <Badge className="deliveryTrendRest delivery_time  deliveryTrending">
                          {item.del_prep_time} | {item.distance}km{" "}
                        </Badge>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="7" xs="6" md="7" sm="6">
                        <div className="ms-2 ">
                          <Badge
                            className="break_hours_badge openHoursTrending  mb-3"
                            variant="outlined"
                          >
                            {item.break_start_time !== ""
                              ? "Break Hours-" +
                                item.break_start_time +
                                "-" +
                                item.break_end_time
                              : null}
                          </Badge>
                        </div>
                      </Col>
                      <Col lg="3" />
                    </Row>
                    <Badge
                      className="Favourite_Badge_HomePage"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {item.isWishList === "1" ? (
                        <AiFillHeart
                          className="favourite"
                          onClick={() => handleFavourites(2, item)}
                        />
                      ) : (
                        <AiOutlineHeart
                          className="favourite"
                          onClick={() => handleFavourites(1, item)}
                        />
                      )}
                    </Badge>
                  </Card>
                  {/* <Card
                    className="TrendingCardHomepage"
                    onClick={() => {
                      ShowRestaurantData(item);
                    }}
                  >
                    <img
                      src={item.banner_image}
                      alt=""
                      className="
     trendingImage 
     img-responsive img-portfolio img-hover
     img-fluid "
                    />

                    {item.min_order_value > 0 ? (
                      <Badge
                        className="minimum_value_trending minimumValueSearch mb-3"
                        variant="outlined"
                      >
                        Minimum Order Value: S${item.min_order_value}
                      </Badge>
                    ) : null}
                    {item.delivery_handled_by === "1" ? (
                      <Badge
                        className="DeliveryHandledByTrending deliveryBySearch mb-3"
                        variant="outlined"
                      >
                        <marquee>
                          {" "}
                          Delivery Handled By Delivery Handled By{" "}
                          {item.rest_name}
                        </marquee>
                      </Badge>
                    ) : null}
                    {item.is_order_later_accept == "1" &&
                    item.restro_is_open != "1" ? (
                      <Badge className="PreOrderLable">Preorder</Badge>
                    ) : null}
                    <Row>
                      <Col lg="8" md="8" xs="9" className="RestTitleFavWidth">
                        <div className="ms-2 mt-2 ">
                          <small className=" FavRestaurent_Title">
                            {" "}
                            {item.rest_name}
                          </small>
                          <br />
                          {item.next_open_time !== "" ? (
                            <Badge className="openBackTimeBadge">
                              <small className="OpenBackTime">
                                {item.next_open_time !== ""
                                  ? "Opening Back" + " " + item.next_open_time
                                  : null}
                              </small>
                            </Badge>
                          ) : null}

                          <p className="mb-2 Restaurent_SubTitle text-muted">
                            {item.res_description}
                          </p>
                        </div>

                        <Badge
                          className="delivery_by mt-2 mb-5"
                          variant="outlined"
                        >
                          {item.Delivery_by}
                        </Badge>
                      </Col>
                      <Col lg="4" md="4" xs="3">
                        {item.restro_is_open === "1" ? (
                          <Badge className="openFavRestFav Restaurent_Open_Close  mt-2">
                            <small>Open</small>
                          </Badge>
                        ) : (
                          <Badge className="Restaurent_Close_home mt-2">
                            <small>Closed</small>
                          </Badge>
                        )}
                        <br />
                        <small>
                          <img
                            src={RatingStart}
                            alt=""
                            className="Restaurent_Rating_star favRating mx-1 pb-1"
                          />
                          <small className="RatingCountItemsRestaurant">
                            {item.avg_rating}/5
                          </small>
                        </small>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="7" md="4" sm="6" xs="7">
                        {item.open_time !== "" || item.close_time !== "" ? (
                          <Badge
                            className="open_hours_badge openHoursFavourite ms-2"
                            variant="outlined"
                          >
                            {"Open Hours-" +
                              item.open_time +
                              "-" +
                              item.close_time}{" "}
                          </Badge>
                        ) : null}
                      </Col>
                      <Col lg="4" md="4" sm="4" xs="4">
                        <Badge className=" FavouriteDeliveryTime">
                          {item.del_prep_time} | {item.distance}km{" "}
                        </Badge>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="7">
                        <div className="ms-2">
                          <Badge
                            className="break_hours_badge breakHoursFavourite mt-2 mb-3"
                            variant="outlined"
                          >
                            {item.break_start_time !== ""
                              ? "Break Hours-" +
                                item.break_start_time +
                                "-" +
                                item.break_end_time
                              : null}
                          </Badge>
                        </div>
                      </Col>
                      <Col lg="3" />
                    </Row>
                    <Badge
                      className="Favourite_Badge_HomePage"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {item.isWishList === "1" ? (
                        <AiFillHeart
                          className="favourite"
                          onClick={() => handleFavourites(2, item)}
                        />
                      ) : (
                        <AiOutlineHeart
                          className="favourite"
                          onClick={() => handleFavourites(1, item)}
                        />
                      )}
                    </Badge>
                  </Card> */}
                </>
              ))
            : "No Data Found"}
        </div>
      </div>
      {/* <Col lg="12" Col md="12">
        <Card className="favourite_card ">
          <Card.Body className="">
            <Row>
              <Col lg="6">
                <p className="Favourite_title mt-5 ms-5">Favourite</p>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <Badge className="Favourite_Badge_profile pt-2 ms-5">
                  <p className="YourFavouriteRestaurent ms-4">
                    Your Favourite Restaurant
                  </p>
                </Badge>
              </Col>
            </Row>

            <Row className="FavCardScrollBar">
              <Col lg="11" xs="12" className="ms-4">
                <Row className="Favourite_item_card mt-4 ">
                  {wishListData.length > 0
                    ? wishListData.map((item, index) => (
                        <Col
                          xl="4"
                          lg="6"
                          md="6"
                          sm="6"
                          xs="11"
                          className="favouriteCardProfile d-flex grid-margin mb-5 gap-3"
                        >
                          <Card
                            className="TrendingCardHomepage"
                            onClick={() => {
                              ShowRestaurantData(item);
                            }}
                          >
                            <img
                              src={item.banner_image}
                              alt=""
                              className="
         trendingImage 
         img-responsive img-portfolio img-hover
         img-fluid "
                            />

                            {item.min_order_value > 0 ? (
                              <Badge
                                className="minimum_value_trending minimumValueSearch mb-3"
                                variant="outlined"
                              >
                                Minimum Order Value: S${item.min_order_value}
                              </Badge>
                            ) : null}
                            {item.delivery_handled_by === "1" ? (
                              <Badge
                                className="DeliveryHandledByTrending deliveryBySearch mb-3"
                                variant="outlined"
                              >
                                <marquee>
                                  {" "}
                                  Delivery Handled By Delivery Handled By{" "}
                                  {item.rest_name}
                                </marquee>
                              </Badge>
                            ) : null}
                            {item.is_order_later_accept == "1" &&
                            item.restro_is_open != "1" ? (
                              <Badge className="PreOrderLable">Preorder</Badge>
                            ) : null}
                            <Row>
                              <Col
                                lg="8"
                                md="8"
                                xs="9"
                                className="RestTitleFavWidth"
                              >
                                <div className="ms-2 mt-2 ">
                                  <small className=" FavRestaurent_Title">
                                    {" "}
                                    {item.rest_name}
                                  </small>
                                  <br />
                                  {item.next_open_time !== "" ? (
                                    <Badge className="openBackTimeBadge">
                                      <small className="OpenBackTime">
                                        {item.next_open_time !== ""
                                          ? "Opening Back" +
                                            " " +
                                            item.next_open_time
                                          : null}
                                      </small>
                                    </Badge>
                                  ) : null}

                                  <p className="mb-2 Restaurent_SubTitle text-muted">
                                    {item.res_description}
                                  </p>
                                </div>

                                <Badge
                                  className="delivery_by mt-2 mb-5"
                                  variant="outlined"
                                >
                                  {item.Delivery_by}
                                </Badge>
                              </Col>
                              <Col lg="4" md="4" xs="3">
                                {item.restro_is_open === "1" ? (
                                  <Badge className="openFavRestFav Restaurent_Open_Close  mt-2">
                                    <small>Open</small>
                                  </Badge>
                                ) : (
                                  <Badge className="Restaurent_Close_home mt-2">
                                    <small>Closed</small>
                                  </Badge>
                                )}
                                <br />
                                <small>
                                  <img
                                    src={RatingStart}
                                    alt=""
                                    className="Restaurent_Rating_star favRating mx-1 pb-1"
                                  />
                                  <small className="RatingCountItemsRestaurant">
                                    {item.avg_rating}/5
                                  </small>
                                </small>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="7" md="4" sm="6" xs="7">
                                {item.open_time !== "" ||
                                item.close_time !== "" ? (
                                  <Badge
                                    className="open_hours_badge openHoursFavourite ms-2"
                                    variant="outlined"
                                  >
                                    {"Open Hours-" +
                                      item.open_time +
                                      "-" +
                                      item.close_time}{" "}
                                  </Badge>
                                ) : null}
                              </Col>
                              <Col lg="4" md="4" sm="4" xs="4">
                                <Badge className=" FavouriteDeliveryTime">
                                  {item.del_prep_time} | {item.distance}km{" "}
                                </Badge>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="7">
                                <div className="ms-2">
                                  <Badge
                                    className="break_hours_badge breakHoursFavourite mt-2 mb-3"
                                    variant="outlined"
                                  >
                                    {item.break_start_time !== ""
                                      ? "Break Hours-" +
                                        item.break_start_time +
                                        "-" +
                                        item.break_end_time
                                      : null}
                                  </Badge>
                                </div>
                              </Col>
                              <Col lg="3" />
                            </Row>
                            <Badge
                              className="Favourite_Badge_HomePage"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {item.isWishList === "1" ? (
                                <AiFillHeart
                                  className="favourite"
                                  onClick={() => handleFavourites(2, item)}
                                />
                              ) : (
                                <AiOutlineHeart
                                  className="favourite"
                                  onClick={() => handleFavourites(1, item)}
                                />
                              )}
                            </Badge>
                          </Card>
                        </Col>
                      ))
                    : "No Data Found"}
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col> */}
    </>
  );
}

export default Favourite;
