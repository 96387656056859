import React, { useEffect } from "react";
import RestaurentViewPage from "../../components/RestaurentView/RestaurentViewPage.jsx";

export default function RestaurentView() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* {sessionStorage.getItem("restaurant_product_inCart") ||
      sessionStorage.getItem("restaurantData") ? ( */}
        <RestaurentViewPage />
      {/* ) : null} */}
    </div>
  );
}
