import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { BsCircleFill } from "react-icons/bs";
import "./CartPopUp.css";
import { IoIosCloseCircle } from "react-icons/io";
import {
  cartValueApi,
  cartValueCheckoutApi,
  checkoutDetailsPostApi,
} from "../../../services/CartCheckOutServices";
import {
  addressValuesSession,
  deliverLatLngData,
  getName,
  getUserData,
  selfPickUpValue,
  sessionLocationData,
  singaporeTimeConverter,
} from "../../../constants/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getUrl } from "../../../services/URl/Url";
function CartIconPopup({ closePopUp, show, cartValueValuesApi, cartApi }) {
  const [cartCheckoutvalue, setCartCheckoutvalue] = useState();
  const [loader, setLoader] = useState(true);

  let userName = getName() ? getName()[0] : null;
  let navigate = useNavigate();

  // const checkoutPostDetailsValuesApi = async (data) => {
  //   let postcheckoutDetailsObjectValuesObject = {
  //     restaurant_id: sessionStorage.getItem("restaurantData")
  //       ? sessionStorage.getItem("restaurantData")
  //       : null,
  //     order_type:selfPickUpValue() ? selfPickUpValue() : "1",
  //     pickup_time: sessionStorage.getItem("pickupFrom")?sessionStorage.getItem("pickupFrom"):singaporeTimeConverter(),
  //     latitude: deliverLatLngData()?.lat,
  //     longitude: deliverLatLngData()?.lng,
  //     pin_address: sessionLocationData()?.pin_address,
  //     delivery_name: userName ? userName : null,
  //     delivery_email: getUserData()?.email,
  //     delivery_mobile: getUserData()?.mobile,
  //     unit_number: sessionLocationData()?.unit_number,
  //     street_address: sessionLocationData()?.street_address,
  //     postal_code: sessionLocationData()?.postal_code,
  //     date_timestamp: singaporeTimeConverter(),
  //   };

  //   try {
  //     let checkoutPostDetailsResponse = await checkoutDetailsPostApi(
  //       postcheckoutDetailsObjectValuesObject
  //     );
  //     setCheckOutDetails(checkoutPostDetailsResponse?.data?.data);
  //   } catch (e) {}
  // };

  const cartCheckOutApiValues = async () => {
    try {
      let cartcheckoutResponse = await cartValueCheckoutApi();
      setCartCheckoutvalue(cartcheckoutResponse?.data?.data);
    } catch (e) {
    } finally {
      setLoader(false);
    }
  };

  let sessLoc = sessionStorage.getItem("userLocation")
    ? Object.keys(JSON.parse(sessionStorage.getItem("userLocation")))?.length !=
      0
    : null;
  useEffect(() => {
    if (
      sessLoc &&
      sessionStorage.getItem("userLocation") &&
      location.pathname != `/restaurants/${RestUrl}`
    ) {
      cartValueValuesApi();
      setTimeout(() => {
        cartCheckOutApiValues();
      }, 100);
    }
  }, []);

  const handleClose = () => {
    closePopUp(false);
  };
  const params = new URLSearchParams();
  const location = useLocation();
  let RestUrl = sessionStorage.getItem("identifier_key")
    ? sessionStorage.getItem("identifier_key")
    : null;

  const handleViewCart = () => {
    sessionStorage.setItem(
      "identifier_key",
      cartCheckoutvalue?.rest_identifier
    );
    let RestUrl = sessionStorage.getItem("identifier_key")
      ? sessionStorage.getItem("identifier_key")
      : null;

    if (location.pathname != `/restaurants/${RestUrl}`) {
      navigate({
        pathname: `/restaurants/${RestUrl}`,
        search: params.toString(), // '?name=John&age=32
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} animation={true}>
        <div className="notification-header">
          <Modal.Title className="tittle">
            Your Cart Items ({cartApi})
          </Modal.Title>
          <Modal.Header closeButton></Modal.Header>
        </div>

        {loader ? (
          <p className="loadingCartPopLoader">Loading Please Wait...</p>
        ) : (
          <Modal.Body>
            {cartCheckoutvalue?.cart_products &&
              cartCheckoutvalue?.cart_products.map((checkoutdataObj, index) => {
                return (
                  <Card className="cartItem cartPopupItemDetails mt-2">
                    <div className="cartProductContainer">
                      <div className="cartPdtImg">
                        {checkoutdataObj?.product_image != "" ? (
                          <img
                            className="itemImages"
                            // width="113%"
                            // height="53%"
                            src={checkoutdataObj?.product_image}
                            alt=""
                          ></img>
                        ) : null}
                      </div>

                      <div className="cartItemsDtls">
                        {/* <BsCircleFill
                          className={
                            checkoutdataObj?.is_veg === "2"
                              ? "itemTypeRed px-1 py-1 "
                              : "itemType px-1 py-1"
                          }
                        /> */}
                        <p className="itemTtle">
                          {checkoutdataObj.product_name}
                        </p>
                        <p className="itmDscrt">{checkoutdataObj.short_desc}</p>
                        <p className="itmPrc">
                          {checkoutdataObj?.offer_price > 0
                            ? "S$" + checkoutdataObj?.offer_price
                            : "S$" + checkoutdataObj?.price}
                        </p>
                      </div>
                    </div>
                  </Card>
                );
              })}
            {!cartCheckoutvalue?.cart_products ? (
              <Card className="cartItem no-data">
                User cart is empty, Please add the products to the cart
              </Card>
            ) : null}
            {cartCheckoutvalue?.cart_products?.length > 0 ? (
              <Row>
                <Button
                  className="cart-btn"
                  onClick={() => handleViewCart()}
                  disabled={
                    location.pathname == `/restaurants/${RestUrl}`
                      ? true
                      : false
                  }
                >
                  View Cart
                </Button>
              </Row>
            ) : null}
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

export default CartIconPopup;
