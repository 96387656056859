import { React, useEffect, useState } from "react";
import { Col, Row, Button, Card } from "react-bootstrap";
import "./DineinCart.css";
import CancelDineinPopup from "../../Popup/MyOrder/Dinein/CancelDineinPopup";
import ChangeDineinPopup from "../../Popup/MyOrder/Dinein/ChangeDineinPopup";
import { myOrdersApi } from "../../../../services/ProfilePageServices";
import dayjs from "dayjs";
import { getUrl } from "../../../../services/URl/Url";

function DineinCart() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [dineInDatas, setDineInDatas] = useState();

  const handleShow = (obj) => {
    setSelectedItem(obj);
    setShow(true);
  };
  const closePopUp = (value) => {
    setShow(value);
  };

  const handleShow1 = (obj) => {
    setSelectedItem(obj);
    setShow1(true);
  };
  const closePopUp1 = (value) => {
    setShow1(value);
  };

  const myOrdersValuesApi = async () => {
    let postMyOrderObject = {
      selected_tab: "2",
      page: "0",
      limit: "100",
    };

    try {
      let dineInResp = await myOrdersApi(postMyOrderObject);
      setDineInDatas(dineInResp.data.data);
    } catch (e) {}
  };

  useEffect(() => {
    myOrdersValuesApi();
  }, []);

  const handleDateConvert = (obj) => {
    var d = new Date(obj.booking_date * 1000);
    var t = new Date(obj.time_slot * 1000);
    const now = t.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const resultDate =
      d.getDate() +
      "/" +
      (d.getMonth() + 1) +
      "/" +
      d.getFullYear() +
      "   at   " +
      t.getHours() +
      ":" +
      t.getMinutes().toString().padStart(2, 0);

    return resultDate;
  };

  return (
    <>
      <div className="order-scroll">
        {dineInDatas?.map((obj) => (
          <>
            <div className="order-global-container">
              <div className="order-container">
                <div className="order-img">
                {obj.mobile_banner_image!==""?
                  <img
                    src={obj.mobile_banner_image}
                    alt=""
                    width="60%"
                    height="60%"
                  />:null}
                </div>
                <div className="order-title">
                  <div className="rest-name">
                    <b>{obj.rest_name}</b>
                    {/* <b>({obj.res_description})</b> */}
                  </div>
                  <div className="addr-name">
                    <p>
                      Address :{obj.rest_pin_address} {obj.rest_postal_code}
                    </p>
                  </div>
                </div>
              </div>
              <div className="order-container shedule-content">
                <div>
                  <div> Name</div>
                  <div className="Details">{obj.fullname}</div>
                </div>
                <div>
                  <div> No of Member</div>
                  <div className="Details">{obj.no_of_people}</div>
                </div>
                <div>
                  <div>Dining Date & Time</div>
                  <div className="Details">{obj.time_slot}</div>
                </div>
                <div>
                  <div>{obj.is_accepted === "2"?"Status":"Category"}</div>
                  <div className="Details">
                    {obj.is_accepted === "0"
                      ? "Dine In"
                      : obj.is_accepted === "1"
                      ? "Accepted by merchant"
                      : obj.is_accepted === "2"
                      ? "Rejected by merchant"
                      : obj.is_accepted === "3"
                      ? "Canceled By Customer"
                      : "Dine In"}
                  </div>
                </div>
              </div>
            </div>

            <div className="order-container schedule-time">
              <div>
                {obj.is_accepted !== "3" && obj.is_accepted !== "2" ? (
                  <Button
                    className="CancelDineInItemProfile"
                    onClick={() => handleShow(obj)}
                  >
                    Cancel
                  </Button>
                ) : null}
                {obj.is_accepted == "0"? (
                  <Button
                    className="ChangeDineInItemProfile ms-3"
                    onClick={() => handleShow1(obj)}
                  >
                    Change
                  </Button>
                ) : null}
              </div>
            </div>
          </>
        ))}
        {dineInDatas && dineInDatas.length == 0 ? (
          <div className="order-container">No data found</div>
        ) : null}
      </div>
      <CancelDineinPopup
        show={show}
        closePopUp={closePopUp}
        selectedItem={selectedItem}
        myOrdersValuesApi={myOrdersValuesApi}
      />
      <ChangeDineinPopup
        show={show1}
        closePopUp={closePopUp1}
        selectedItem={selectedItem}
        myOrdersValuesApi={myOrdersValuesApi}
      />
    </>
  );
}

export default DineinCart;
