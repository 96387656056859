import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  FormControl,
} from "react-bootstrap";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import "./FilterWallet.css";
import { filterWalletApi } from "../../../../services/ProfilePageServices";
import { InputLabel, MenuItem, Select } from "@mui/material";
function FilterWalletPopup({
  LocationPopUp,
  showFilterPopup,
  handleFilterData,
}) {
  const CloseLocationPopUp = () => {
    LocationPopUp(false); //callback function
  };

  const [inputs, setInputs] = useState({
    from_date: "",
    to_date: "",
  });
  const [mode, setMode] = useState(0);
  const [selectedMode, setSelectedMode] = useState(0);
  const filterWalletValuesApi = async () => {
    let from = new Date(inputs.from_date);
    let to = new Date(inputs.to_date);
    let fromDate =
      Date.parse(
        `"${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`
      ) / 1000;
    let toDate =
      Date.parse(`"${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()+1}`) /
      1000;
    let postFilterWalletApiObject = {
      filter_type: mode, //# 1 : By date 2 : By type
      from_date: fromDate,
      to_date: toDate,
      filter_by: selectedMode, //# 1 - Cashback 2 - Money Added 3 debited
      page: "0",
      limit: "100",
    };

    try {
      let filterWalletApiResponse = await filterWalletApi(
        postFilterWalletApiObject
      );
      handleFilterData(filterWalletApiResponse.data.data);
      CloseLocationPopUp();
    } catch (e) {}
  };

  const handleOnChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleClearAll = () => {
    setInputs({
      from_date: "",
      to_date: "",
    });
    setSelectedMode(0);
    setMode(0);
  };

  const handleChange = (event) => {
    setMode(event.target.value);
    if (event.target.value === 0) {
      setSelectedMode(0);
    } else {
      setInputs({
        ...inputs,
        from_date: "",
        to_date: "",
      });
    }
  };

  const handleModeChange = (event) => {
    setSelectedMode(event.target.value);
  };
  var d = new Date();
  let curruntDate = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, 0)}-${d.getDate()}`;

  
  return (
    <>
      <Modal
        show={showFilterPopup}
        onHide={CloseLocationPopUp}
        animation={true}
      >
        <Modal.Header className="locationPopUp_Header border-0" closeButton>
          <div className="filterWallethead">
            <p>Filter</p>
            <u className="clearAllWalletText">
              <p className="clearAllWalletText" onClick={handleClearAll}>
                Clear All
              </p>
            </u>
          </div>
        </Modal.Header>
        <ModalBody>
          <div className="DateContainerWallet">
            <p> Filter By</p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mode}
              label="Filter By"
              onChange={handleChange}
            >
              <MenuItem value={0}>Select</MenuItem>
              <MenuItem value={1}>Date</MenuItem>
              <MenuItem value={2}>Type</MenuItem>
            </Select>
          </div>
          {mode === 1 ? (
            <>
              <div className="DateContainerWallet">
                <p> From Date</p>
                <Stack component="form" noValidate spacing={3}>
                  <TextField
                    id="date"
                    className="FilterData"
                    type="date"
                    InputProps={{ inputProps: { max: curruntDate} }}
                    defaultValue="2017-05-24"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="from_date"
                    value={inputs.from_date}
                    onChange={(e) => handleOnChange(e)}
                  />
                </Stack>
              </div>
              <div className="DateContainerWallet">
                <p>To Date</p>
                <Stack component="form" noValidate spacing={3}>
                  <TextField
                    id="date"
                    type="date"
                    
                    InputProps={{ inputProps: { max: curruntDate ,min:inputs.from_date} }}
                    defaultValue="2017-05-24"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="to_date"
                    value={inputs.to_date}
                    onChange={(e) => handleOnChange(e)}
                  />
                </Stack>
              </div>
            </>
          ) : null}
          {mode === 2 ? (
            <>
              <div className="DateContainerWallet">
                <p> Filter Mode</p>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedMode}
                  label="Filter By"
                  onChange={handleModeChange}
                >
                  <MenuItem value={4}>All</MenuItem>
                  <MenuItem value={1}>Cashback</MenuItem>
                  <MenuItem value={2}>Money Added</MenuItem>
                  <MenuItem value={3}>Debited</MenuItem>
                </Select>
              </div>
            </>
          ) : null}
          <div className="FilterButtonProfile">
            <Button className="CloseButtonFilterProfile"  onClick={() => CloseLocationPopUp()}>Close</Button>
            <Button
              className="ApplyButtonFilterProfile"
              onClick={() =>mode!==0? filterWalletValuesApi():null}
              disabled={mode==0}
            >
              Apply
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default FilterWalletPopup;
