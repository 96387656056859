import React, { useEffect, useState } from "react";
import MenuItems from "../../components/Restaurent__list/Menu/MenuItems";
import LandingHeader from "../../components/Landing/Landing_header/Landingheader";
import { getUserData, getUserType } from "../../constants/Utils";
import { useLocation } from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties, setUserId } from "firebase/analytics";

import { ProfiledetailApi } from "../../services/ProfilePageServices";
// import Location from "../../components/Landing/Location/Location";
import VerifyAccount from "../../components/Landing/VerifyAccount/VerifyAccount";


const RestaurentList = () => {
  const [itemIdState, setItemIdState] = useState();
  const [foodIdState, setFoodIdState] = useState();
  const [showLocation, setShowLocation] = useState(true);


  const firebaseConfig = {
    apiKey: "AIzaSyD_FMTXLsC_dBkNPGa4BnaP65L7JvE9AO0",
    authDomain: "kerala-eats-312105.firebaseapp.com",
    projectId: "kerala-eats-312105",
    storageBucket: "kerala-eats-312105.appspot.com",
    messagingSenderId: "987804453892",
    appId: "1:987804453892:web:8f61f10109ac0707787951",
    measurementId: "G-Q6R84M0DDZ"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // Initialize Analytics and get a reference to the service
  const analytics = getAnalytics(app);
  setUserId(analytics, 'U1000');
  useEffect(() => {
    logEvent(analytics, 'Home-page', {
      event_type: 'home page entered',
      event_id: 'P12453'
    });

    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  useEffect(() => {
    if (getUserType() != 2) {
      window.initHippo({
        appSecretKey: "804f8e693c07559e9a03db0721f711e2",
        hideNewConversation: "1",
        alwaysSkipBot: true,
        language: "en",
        color: "#FF6838",
        email: getUserData()?.email,
        name: getUserData()?.fullname,
        phone: getUserData()?.mobile,
        customAttributes: {
          appId: "2",
        },
      });
    }
  }, []);
  
  const [addressChangeData, setAddressChangeData] = useState({});

  const currentLatLng = (currntLatLng) => {
    setAddressChangeData(currntLatLng);
  };
  const itemIdData = (value) => {
    setItemIdState(value);
  };
  const foodIdData = (value) => {
    setFoodIdState(value)
  };

  const ProfiledetailValuesApi = async () => {
    let postProfiledetailObject = {
      page:0,
      limit:1000
    };

    try {
      let ProfiledetailResponse = await ProfiledetailApi(
        postProfiledetailObject
      );

      // console.log("ProfiledetailResponse",ProfiledetailResponse);
      // setProfileDetailsData(ProfiledetailResponse.data.data);
      // setProfileName(ProfiledetailResponse.data.data?.user_details?.fullname)
      const data = window.sessionStorage.getItem("otpResponse");
      const newData = JSON.parse(data);

      console.log("ProfiledetailResponse",ProfiledetailResponse.data.data?.user_details.is_email_verified);

      sessionStorage.setItem(
        "emailVerified",
        JSON.stringify({
          
          is_email_verified: ProfiledetailResponse.data.data?.user_details?.is_email_verified,
        })
      );
    } catch (e) {}
  };
  const emailVerified = JSON.parse(sessionStorage.getItem("emailVerified"));


  const showLocationPopup = () => setShowLocation(true);


  const LocationPopUp = (value) => {
    setShowLocation(value);
  };


  useEffect(()=>{
    ProfiledetailValuesApi();
  },[])

  return (
    <>
      <div>
        <LandingHeader isRestaurant={true} currentLatLng={currentLatLng} foodIdState={foodIdState} itemIdState={itemIdState}/>
      </div>
      {sessionStorage.getItem("userLocation") ? (
        <div>
          <MenuItems addressChangeData={addressChangeData} itemIdData={itemIdData} foodIdData={foodIdData}/>
        </div>
      ) : null}

{emailVerified?.is_email_verified === "0" && (
      <VerifyAccount  showLocation={showLocation} LocationPopUp={LocationPopUp} />
    )}
    </>
  );
};

export default RestaurentList;
