import React from "react";
import { AiFillStar } from "react-icons/ai";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

function ProfileRatingData({ profileDetailsData }) {
    const location=useLocation
    let navigate = useNavigate();

    const NavigateToRestaurant = (item) => {
        let restId = sessionStorage.getItem("restaurantData");
        let cartId = sessionStorage.getItem("restaurant_product_inCart");
    
        sessionStorage.setItem("restaurantData",item.restaurant_id );
        sessionStorage.setItem("identifier_key",item.rest_identifier );
    
        let RestUrl=sessionStorage.getItem("identifier_key")?sessionStorage.getItem("identifier_key"):null
    
          navigate(`/restaurants/${RestUrl}`);
        
      };
  return (
    <>
            <div className="ReviewsContentScrollDiv">

    <p className="ReviewsTextHead">Reviews</p>
      {profileDetailsData?.rating_details?.map((item, index) => (
            <div className="review-content" onClick={()=>NavigateToRestaurant(item)}>

            <div className="RatingProfileData">
            <img src={item.banner_image}  alt="" className="RatingRestImage"/>
<div className="ItemRestNameReviewDiv">
            <p className="itemRatingResText ms-3"> {item.rest_name}
            <br/>
           
           <small className="itemREviewProfile"> {item.review}</small></p>
           </div>
            <div className="itemRatingContent ms-2">
           
           <p className="itemRatingDataProduct itemRatingdataProfile"><AiFillStar className="itemRatingstar"/>{item.given_rating}/5</p>
          </div>
          </div>
        </div>
       
      ))}
      </div>
    </>
  );
}

export default ProfileRatingData;
