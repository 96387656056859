import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card, Form, Modal, Badge } from "react-bootstrap";
import OrderPlacedImage from "./../../../../../Asserts/TrackOrder/order-placed@2x.png";
import "./OrderTrackPopUp.css";
import ChatOption from "../../../../../Asserts/ProfilePage/chatIcon.png";
import RoundFilled from "../../../../../Asserts/TrackOrder/RoundFilled.svg";
import RoundNotFilled from "../../../../../Asserts/TrackOrder/RoundHide.svg";

function OrderTrackPopUp({
  viewMoreOrder,
  trackOrderDetail,
  closePopUp,
  show,
  variantData,
}) {
  const handleClose = () => {
    closePopUp(false);
  };

  const viewMoreOrderDetails = viewMoreOrder?.order;
 
  const productListData = viewMoreOrder?.product_detail;

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const trackDetails = trackOrderDetail?.tracker_info;

  let varients = viewMoreOrder?.product_detail;
  varients?.map((item, index) => {
   
    // item?.variants.map((obj, index) =>
    //   console.log(obj.variant_name, obj.variant_price, obj.variant_type_name)
    // );
  });

  return (
    <>
      <Modal
        className="track_modalTrack .modal-dialog modal-xl mx-2"
        show={show}
        onHide={handleClose}
      >
        <Row>
          <Modal.Header
            className="ModalHeaderTrackPopup"
            closeButton
          ></Modal.Header>
        </Row>
        <Row>
          <Col lg="12" sm="12" md="12" xs="12" className="trackTittle">
            <p className="Track_Order_Title">TRACK ORDER</p>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <p className="track_order_title">
              {viewMoreOrderDetails?.rest_name}
            </p>
          </Col>
        </Row>

        <div className="trckPopWrpr mx-0 px-3">
          <div className="lftTrkPopup track_popup">
            {trackDetails?.map((item, index) => (
              <>
                <div
                  className={`TrackOrderContainer ${
                    trackDetails?.length == index + 1 ? "last" : ""
                  }`}
                >
                  <div className="TableClassTrackOrder">
                    {item.active_status === 2 || item.active_status === 1 ? (
                      <img src={RoundFilled} alt="" />
                    ) : null}
                    {item.active_status === 0 ? (
                      <img src={RoundNotFilled} alt="" />
                    ) : null}
                    {item.active_status === 1 ? (
                      <div className="OrderStatusCompleted mt-1"></div>
                    ) : null}
                  </div>
                  {/* <div className="imageContainerTable">
                    <img
                      className="TrackOrderImages"
                      src={item.image_url}
                      alt=""
                      width="80%"
                    />
                  </div> */}
                  <div className="textContainerTrack">
                    <small className="OrderTextTrackOrder">
                      {item.tracker_label}
                    </small>
                    <br />
                    <small className="trackerTextOrder">
                      {item.tracker_text}
                    </small>
                    {item.track_link !== "" && item.active_status === 2 ? (
                      <u style={{ color: "#FF6838", fontWeight: "600" }}>
                        <br />
                        <small
                          className="trackTextOrder"
                          onClick={() => openInNewTab(item.track_link)}
                        >
                          Track
                        </small>
                      </u>
                    ) : null}
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="rtTrkPopup">
            <h4 className="OrderNumberTrackOrder mb-2 ps-2 text-start">
              {viewMoreOrderDetails?.order_number}
            </h4>
            <table className="itemDetailsTableTrack">
              <tr>
                <td>Name:</td>
                <th>{viewMoreOrderDetails?.delivery_name}</th>
              </tr>
              <tr>
                <td>{viewMoreOrderDetails?.address_label}:</td>
                <th>{viewMoreOrderDetails?.address}</th>
              </tr>
              <tr>
                <td>Order Date & Time:</td>
                <th>{viewMoreOrderDetails?.order_time}</th>
              </tr>
              <tr>
                <td>Schedule Date of Time:</td>
                <th>{viewMoreOrderDetails?.schedule_time}</th>
              </tr>
              <tr>
                <td>Special Instruction:</td>
                <th>{viewMoreOrderDetails?.remark}</th>
              </tr>
            </table>
            <div className="ordrTblsDtls">
              <div className="ordrPopupHdr row mx-0">
                <div className="col-2">Qty.</div>
                <div className="col-7">Item</div>
                <div className="col-3 text-end">Price</div>
              </div>
              {varients?.map((item, index) => (
                <>
                  <div className="displayContentBadge row mx-0">
                    <div className="col-2"> {item.product_quantity}</div>
                    <div className="col-7">{item.product_name}</div>
                    <div className="col-3 text-end">
                      {" "}
                      {item.product_total_price}
                    </div>
                  </div>
                  {item?.variants != "" ? (
                    <div className="displayContentBadge row mx-0">
                      <div className="col-2"></div>
                      <div className="col-7 py-0">
                        <small className="varientTextData">VARIANTS:</small>
                        {item?.variants.map((obj, index) => {
                          return (
                            <small className="varientdataProfile">
                              {" "}
                              {obj.variant_name}({obj.variant_type_name}){" "}
                            </small>
                          );
                        })}
                      </div>
                      <div className="col-3"></div>
                    </div>
                  ) : null}
                </>
              ))}
              <table className="ItemOrderTable w-100 text-end">
                <tr>
                  <td>Item total:</td>
                  <th>$ {viewMoreOrderDetails?.sub_total}</th>
                </tr>
                <tr>
                  <td>Delivery Charges:</td>
                  <th>$ {viewMoreOrderDetails?.dc_amount}</th>
                </tr>
                <tr>
                  <td>Order Total:</td>
                  <td className="TotalMoneyValueTrack">
                    {" "}
                    $ {viewMoreOrderDetails?.grand_total}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        {/* <Row>
          <Col className="" lg="4" sm="4" md="4" xs="4"></Col>
          <Col lg="8" xs="8" sm="8" md="8" className="ms-5 pop-up">
            <Row>
              <Col lg="5">
                <p className="OrderNumberTrackOrder mb-2 ps-2 text-start">
                  {viewMoreOrderDetails?.order_number}
                </p>
              </Col>
            </Row>

            <div className="ordrTblsDtls">
              <div className="ordrPopupHdr row mx-0">
                <div className="col-md-2">Quantity</div>
                <div className="col-md-6">Item</div>
                <div className="col-md-4">Price</div>
              </div>
            </div>

            <Row>
              <Col lg="12" md="12" sm="12" xs="12" className="pop-up">
                {varients?.map((item, index) => (
                  <>
                    <div className="displayContentBadge row">
                      <div className="col-md-2"> {item.product_quantity}</div>
                      <div className="col-md-6">{item.product_name}</div>
                      <div className="col-md-4">
                        {" "}
                        {item.product_total_price}
                      </div>
                    </div>

                    <table className="table-containerTable">
                      <tbody>
                        {item?.variants != "" ? (
                          <tr className="VarientRowDetails">
                            <td></td>
                            <td className="VarientTextProfile">
                              <small className="varientTextData">
                                VARIANTS:
                              </small>
                              {item?.variants.map((obj, index) => {
                                return (
                                  <small className="varientdataProfile">
                                    {" "}
                                    {obj.variant_name}({obj.variant_type_name}){" "}
                                  </small>
                                );
                              })}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </>
                ))}
                <Row className="OrderTrackRow">
                  <table className="ItemOrderTable mt-3">
                    <tr>
                      <td>Item total:</td>
                      <th>$ {viewMoreOrderDetails?.sub_total}</th>
                    </tr>
                    <tr>
                      <td>Delivery Charges:</td>
                      <th>$ {viewMoreOrderDetails?.dc_amount}</th>
                    </tr>
                    <tr>
                      <td>Order Total:</td>
                      <td className="TotalMoneyValueTrack">
                        {" "}
                        $ {viewMoreOrderDetails?.grand_total}
                      </td>
                    </tr>
                  </table>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row> */}
      </Modal>
    </>
  );
}

export default OrderTrackPopUp;
