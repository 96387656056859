import { React, useEffect, useState } from "react";
import LoginView from "./Login/LoginView";
import { Col, Row, Card, FormSelect } from "react-bootstrap";
import "./Login.css";
import CartItems from "../RestaurentView/CartPopupItems/CartItems";
import DesiEatsImage from "../Landing/Items/DesiEatsImage";
import AddressView from "./Login/AddressView";
import Payment from "./Payment/Payment";
import Geocode from "react-geocode";

import {
  cartValueApi,
  checkoutDetailsPostApi,
} from "../../services/CartCheckOutServices";
import RestaurentViewPage from "../RestaurentView/RestaurentViewPage.jsx";
import {
  addressValuesSession,
  deliverLatLngData,
  getName,
  getUserData,
  selfPickUpValue,
  sessionLocationData,
  singaporeTimeConverter,
} from "../../constants/Utils";
import { HiOfficeBuilding } from "react-icons/hi";
import { ImLocation } from "react-icons/im";
import { AiTwotoneHome } from "react-icons/ai";
import { AllAddressApi } from "../../services/ProfilePageServices";
import LandingHeader from "../Landing/Landing_header/Landingheader";
import { processPaymentThreedsApi } from "../../services/PaymentService";
import Loader from "../Loaders/Loader";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import NoCart from "../../Asserts/emptycart.svg";
function Login() {
  const [globalCheckoutDetails, setGlobalCheckoutDetails] = useState([]);
  const [allAddressData, setAllAddressData] = useState([]);
  const [addressData, setAddressData] = useState();
  const navigate = useNavigate();

  const [activeAddress, setActiveAddress] = useState();

  // const AllAddressDataApi = async () => {
  //   let postAllAddressObj = {
  //     page: "0",
  //     limit: "5",
  //   };

  //   try {
  //     let allAddressApidataResponse = await AllAddressApi(postAllAddressObj);
  //     setAllAddressData(allAddressApidataResponse.data.data);
  //   } catch (e) {}
  // };
  const [cartValue, setCartValue] = useState();
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [noProducts, setNoProducts] = useState(false);

  let userName = getName() ? getName()[0] : null;

  const checkoutPostDetailsValuesApi = async (data) => {
    setCheckoutLoading(true);
    let postcheckoutDetailsObjectValuesObject = {
      restaurant_id: sessionStorage.getItem("restaurantData")
        ? sessionStorage.getItem("restaurantData")
        : null,
      order_type: selfPickUpValue() ? selfPickUpValue() : "1",
      pickup_time: sessionStorage.getItem("pickupFrom")
        ? sessionStorage.getItem("pickupFrom")
        : Date.now() / 1000,
      // : singaporeTimeConverter(),
      latitude: deliverLatLngData()?.lat,
      longitude: deliverLatLngData()?.lng,
      pin_address: sessionLocationData()?.pin_address,
      delivery_name: userName ? userName : null,
      delivery_email: getUserData()?.email,
      delivery_mobile: getUserData()?.mobile,
      unit_number: sessionLocationData()?.unit_number,
      street_address: sessionLocationData()?.street_address,
      postal_code: sessionLocationData()?.postal_code,
      date_timestamp: Date.now() / 1000,
      // date_timestamp: singaporeTimeConverter(),
    };

    try {
      let checkoutPostDetailsResponse = await checkoutDetailsPostApi(
        postcheckoutDetailsObjectValuesObject
      );

     

      if (window.location.pathname === "/payment") {
    
        if (checkoutPostDetailsResponse?.data?.status == 200) {
          if (checkoutPostDetailsResponse?.data?.data?.length === 0) {
            setNoProducts(true);
          }
        }
      }
      setGlobalCheckoutDetails(checkoutPostDetailsResponse?.data?.data);
      setTimeout(() => {
        setCheckoutLoading(false);
      }, 800);
    } catch (e) {}
  };

  useEffect(() => {
    checkoutPostDetailsValuesApi();
    // cartValueValuesApi();
  }, []);

  const cartValueValuesApi = async () => {
    try {
      let cartValueResponse = await cartValueApi();
      setCartValue(cartValueResponse.data.data);
    } catch (e) {}
  };

  // const [timeSlotFlag, setTimeSlotFlag] = useState(false);
  // const getTimeSlotFlag = (flag) => {
  //   setTimeSlotFlag(flag);
  // };
  Geocode.setApiKey("AIzaSyAZNYje65H5kEiuMuF_gFmDwloZLmuIv-I");
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  // const handleAddressOnChange = (e) => {
  //   if (e.target.value) {
  //     allAddressData?.map((address) => {
  //       if (e.target.value.includes(address.pin_address)) {
  //         sessionStorage.setItem("userLocation", JSON.stringify(address));
  //         Geocode.fromAddress(e.target.value).then(
  //           (response) => {
  //             const { lat, lng } = response.results[0].geometry.location;
  //             sessionStorage.setItem(
  //               "deliverLatLng",
  //               JSON.stringify({ lat, lng, pin_address: address.pin_address,label_type:address?.label_type })
  //             );
  //             setAddressData(address.pin_address);
  //           },
  //           (error) => {
  //             console.error(error);
  //           }
  //         );
  //       }
  //     });
  //   }

  //   let value = e.target.value;
  //   sessionStorage.setItem("deliveryAddress", value);
  //   if (value) {
  //     if (value.includes("Home")) {
  //       setActiveAddress("Home");
  //     } else if (value.includes("Office")) {
  //       setActiveAddress("Office");
  //     } else if (value.includes("Others")) {
  //       setActiveAddress("Others");
  //     } else {
  //       setActiveAddress("Others");
  //     }
  //   }
  // };
  // useEffect(() => {
  //   AllAddressDataApi();
  //   if (sessionStorage.getItem("userLocation")) {
  //     setAddressData(
  //       JSON.parse(sessionStorage.getItem("userLocation")).pin_address
  //     );
  //   }
  // }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    if (windowWidth < 800) {
      setWindowWidth(false);
    }
  }, []);
  const [clientSecret, setClientSecret] = useState("");
  const [publishableKey, setPublishableKey] = useState("");
  const [responseStatus, setResponseStatus] = useState();

  let payAmount = sessionStorage.getItem("ActualAutoTotal");
  const processPaymentThreedsApiValues = async () => {
    let postPaymentDetailsApiObject = {
      total_amount: payAmount,
    };

    try {
      let walletDetailsApiResponse = await processPaymentThreedsApi(
        postPaymentDetailsApiObject
      );

      setClientSecret(walletDetailsApiResponse.data.data.clientSecret);
      sessionStorage.setItem(
        "clientSecret",
        walletDetailsApiResponse.data.data.clientSecret
      );
      setPublishableKey(walletDetailsApiResponse.data.data.publishableKey);
      setResponseStatus(walletDetailsApiResponse?.data?.status);
    } catch (e) {}
  };

 
  return (
    <>
      {checkoutLoading ? <Loader /> : ""}
      {noProducts && (
        <div className="emptyCartDiv">
          <div className="eptyInrDiv">
            <img src={NoCart} />
            <h4>Oops! Your cart seems to be empty</h4>
            <p>
              There is no products available in your cart, please add products
              to the cart to proceed with payment
            </p>
            <Link to="/home">
              <button className="btn btn-primary">BROWSE PRODUCTS</button>
            </Link>
          </div>
        </div>
      )}
      {windowWidth ? (
        <Row>
          <Col lg="8">
            <LandingHeader
              checkoutPostDetailsValuesApi={checkoutPostDetailsValuesApi}
            />
            {/*--------------------------------BODY Content------------------------*/}
            <Card className="guestLogin_card ms-5">
              {/* <LoginView getTimeSlotFlag={getTimeSlotFlag} /> */}
              <AddressView />
              {/*----------------------------------------- Payment Method------------------*/}

              {JSON.parse(sessionStorage.getItem("IntentPaymentId"))
                ?.paymentStatus != "succeeded" ? (
                <Payment
                  cartValue={cartValue}
                  globalCheckoutCallback={checkoutPostDetailsValuesApi}
                  globalCheckoutDetails={globalCheckoutDetails}
                  clientSecret={clientSecret}
                  responseStatus={responseStatus}
                  publishableKey={publishableKey}
                  // timeSlotFlag={timeSlotFlag}
                />
              ) : (
                ""
              )}
            </Card>
          </Col>
          <Col lg="4" className="CartItemsPaymentItems">
            <CartItems
              globalCheckoutDetails={globalCheckoutDetails}
              globalCheckoutCallback={checkoutPostDetailsValuesApi}
              processPaymentThreedsApiValues={processPaymentThreedsApiValues}
              checkoutLoading={checkoutLoading}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col lg="4">
            <CartItems
              globalCheckoutDetails={globalCheckoutDetails}
              globalCheckoutCallback={checkoutPostDetailsValuesApi}
            />
          </Col>
          <Col lg="8">
            <LandingHeader
              checkoutPostDetailsValuesApi={checkoutPostDetailsValuesApi}
            />
            {/*--------------------------------BODY Content------------------------*/}
            <Card className="guestLogin_card ms-5">
              {/* <LoginView getTimeSlotFlag={getTimeSlotFlag} /> */}
              <AddressView />
              {/*----------------------------------------- Payment Method------------------*/}

              {JSON.parse(sessionStorage.getItem("IntentPaymentId"))
                ?.paymentStatus != "succeeded" ? (
                <Payment
                  cartValue={cartValue}
                  globalCheckoutCallback={checkoutPostDetailsValuesApi}
                  globalCheckoutDetails={globalCheckoutDetails}
                  // timeSlotFlag={timeSlotFlag}
                />
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Login;
