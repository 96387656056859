import React from "react";
import "./Loader.css";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <>
      <div className="loaderWrapper">
        <div>
          <CircularProgress />
        </div>
      </div>
    </>
  );
};

export default Loader;
