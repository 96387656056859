import React, { useEffect, useState } from "react";
import { Col, FormSelect, Modal, Row } from "react-bootstrap";
import DesiEatsImage from "../Landing/Items/DesiEatsImage";
import CartItemBody from "./CartItemsList/CartItemBody";
import CartItems from "./CartPopupItems/CartItems";
import Tabpanel from "./CartItemsList/Tabpanelitems/TabPanel";
import "./RestaurentViewPage.css";
import { AiTwotoneHome } from "react-icons/ai";
import { HiOfficeBuilding } from "react-icons/hi";
import { ImLocation } from "react-icons/im";
import { AllAddressApi } from "../../services/ProfilePageServices";
import { checkoutDetailsPostApi } from "../../services/CartCheckOutServices";
import { withRouter } from "react-router-dom";

import {
  addressValuesSession,
  deliverLatLngData,
  getName,
  getUserData,
  selfPickUpValue,
  sessionLocationData,
  singaporeTimeConverter,
} from "../../constants/Utils";
import Geocode from "react-geocode";
import { checkOutDetailsFunc } from "../../containers/app/features/CounterSlice";
import { useDispatch, useSelector } from "react-redux";
import LandingHeader from "../Landing/Landing_header/Landingheader";
import { ProductListingApi } from "../../services/RestaurantViewPageServices";
import { useNavigate, useParams } from "react-router-dom";
import { signupVerifyContinueApi } from "../../services/Landingservice";
import Location from "../Landing/Location/Location";

function RestaurentHeader() {
  const { restIdentifierData } = useParams();
  const [allAddressData, setAllAddressData] = useState([]);
  const [activeAddress, setActiveAddress] = useState();
  const [globalCheckoutDetails, setGlobalCheckoutDetails] = useState([]);
  const [addressData, setAddressData] = useState();
  const [addRemoveVariantCount, setaddRemoveVariantCount] = useState(0);
  const [isMobileLandind, setIsMobileLanding] = useState(false);
  const [isViewCart, setIsViewCart] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const showLocationPopup = () => setShowLocation(true);
  const LocationPopUp = (value) => {
    setShowLocation(value);
  };
  let delLattitude = deliverLatLngData()?.lat;
  sessionStorage.setItem("paymentPage", 1);
  const dispatch = useDispatch();
  dispatch(checkOutDetailsFunc(globalCheckoutDetails?.item_total));

  let userName = getName() ? getName()[0] : null;

  const AllAddressDataApi = async () => {
    let postAllAddressObj = {
      page: "0",
      limit: "5",
    };

    try {
      let allAddressApidataResponse = await AllAddressApi(postAllAddressObj);
      setAllAddressData(allAddressApidataResponse.data.data);
    } catch (e) {}
  };
  const [productItems, setProductItems] = useState([]);
  const [filterProductItems, setFilterProductItems] = useState([]);
  const [isVariant, setIsVariant] = useState();
  const restaurantDatas = useSelector((state) => state.counter.restaurantid);
  const [restStatus, setRestStatus] = useState();
  const [dateTimestamp, setDateTimestamp] = useState(
    Math.floor(Date.now() / 1000)
  );
  let navigate = useNavigate();
  const params = new URLSearchParams();

  let RestaurantId = sessionStorage.getItem("restaurantData")
    ? sessionStorage.getItem("restaurantData")
    : null;
  // let RestaurantId = sessionStorage.getItem("restaurant_product_inCart")
  //   ? sessionStorage.getItem("restaurant_product_inCart")
  //   : sessionStorage.getItem("restaurantData")
  //   ? sessionStorage.getItem("restaurantData")
  //   : 0;
  if (sessionStorage.getItem("restaurantData") == undefined) {
    sessionStorage.setItem("isDirectLanding", 1);
    RestaurantId = 0;
  }
  const getGuestUserApi = async () => {
    // setResetForm(!resetForm);
    let postObject = {
      fullname: "",
      email: "",
      mobile: "",
      device_id: "",
      device_type: "2",
      device_token: "123456789",
      hear_about_us: "",
      hear_about_us_val: "",
      app_id: "2",
      user_type: "2",
    };

    try {
      let user = JSON.parse(sessionStorage.getItem("otpResponse"));
      if (!user) {
        let response = await signupVerifyContinueApi(postObject);
      }
    } catch (e) {}
  };
  const [loader, setLoader] = useState(true);
  const productListingValuesApi = async () => {
    // sessionStorage.setItem("identifier_key", restIdentifierData);

    let RestUrl = sessionStorage.getItem("identifier_key")
      ? sessionStorage.getItem("identifier_key")
      : null;
    let postProductListingValuesObject = {
      page: "0",
      limit: "",
      lat: deliverLatLngData()?.lat,
      lng: deliverLatLngData()?.lng,
      restaurant_id:
        sessionStorage.getItem("identifier_key") != undefined
          ? RestaurantId
          : "0",
      date_timestamp: dateTimestamp,
      is_veg: "2", //# 1 - Veg 2 - Non veg
      identifier_key:
        sessionStorage.getItem("identifier_key") != undefined
          ? sessionStorage.getItem("identifier_key")
            ? sessionStorage.getItem("identifier_key")
            : null
          : restIdentifierData,
      // "spice-junction",
    };

    try {
      let productListingValuesResponse = await ProductListingApi(
        postProductListingValuesObject
      );
      setRestStatus(productListingValuesResponse?.data);
      setProductItems(productListingValuesResponse.data?.data);
      setFilterProductItems(productListingValuesResponse.data?.data);
      setIsVariant(productListingValuesResponse.data?.data?.category_list);
      sessionStorage.setItem(
        "restaurantData",
        productListingValuesResponse.data?.data?.restaurant_id
      );
    } catch (e) {
    } finally {
      setLoader(false);
    }
  };
  const [prodCartVal, setProdCartVal] = useState();
  let prodCart = sessionStorage.getItem("restaurant_product_inCart");
  let restHomeId = sessionStorage.getItem("restaurantData");
  let restId = prodCart;
  if (prodCart != undefined && restHomeId != undefined) {
    restId = restHomeId;
   
  } else {
   
    restId = prodCart;
    
  }

  const checkoutPostDetailsValuesApi = async (data) => {
    setProdCartVal(prodCart);
    let postcheckoutDetailsObjectValuesObject = {
      restaurant_id:
        prodCart && prodCart != "undefined"
          ? prodCart
          : sessionStorage.getItem("restaurantData")
          ? sessionStorage.getItem("restaurantData")
          : null,
      order_type: selfPickUpValue() ? selfPickUpValue() : "1",
      pickup_time: sessionStorage.getItem("pickupFrom")
        ? sessionStorage.getItem("pickupFrom")
        : dateTimestamp,
      // : singaporeTimeConverter(),
      latitude: deliverLatLngData()?.lat,
      longitude: deliverLatLngData()?.lng,
      pin_address: sessionLocationData()?.pin_address,
      delivery_name: userName ? userName : null,
      delivery_email: getUserData()?.email,
      delivery_mobile: getUserData()?.mobile,
      unit_number: sessionLocationData()?.unit_number,
      street_address: sessionLocationData()?.street_address,
      postal_code: sessionLocationData()?.postal_code,
      date_timestamp: dateTimestamp,
      // date_timestamp: singaporeTimeConverter(),
    };
   
    try {
     
      if (postcheckoutDetailsObjectValuesObject.restaurant_id) {
        let checkoutPostDetailsResponse = await checkoutDetailsPostApi(
          postcheckoutDetailsObjectValuesObject
        );
        setGlobalCheckoutDetails(checkoutPostDetailsResponse?.data?.data);
      }
    } catch (e) {}
  };
  useEffect(() => {
    setDateTimestamp(Math.floor(Date.now() / 1000));
  }, []);
  useEffect(() => {
    sessionStorage.setItem("identifier_key", restIdentifierData);
    productListingValuesApi();
  }, [restIdentifierData]);
  useEffect(() => {
    setDateTimestamp(Math.floor(Date.now() / 1000));
  }, [delLattitude]);
  useEffect(() => {
    if (window.innerWidth < 800) {
      setIsMobileLanding(true);
    }
    // checkoutPostDetailsValuesApi();
   
    if (sessionStorage.getItem("userLocation")) {
      setAddressData(
        JSON.parse(sessionStorage.getItem("userLocation")).pin_address
      );
    }
  }, []);

  Geocode.setApiKey("AIzaSyAZNYje65H5kEiuMuF_gFmDwloZLmuIv-I");
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  const handleCloseFormsPopUp = () => {
    setIsViewCart(false);
  };
  (function () {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  })();
  
  const [promoManualCode, setPromoManualCode] = useState(false);

  const promoManual = (value) => {
    setPromoManualCode(value);
  };
 
  return (
    <div className="res-container">
      <div
        className={
          isMobileLandind
            ? "restaurent-header-container-full-width"
            : "restaurent-header-container"
        }
      >
        {" "}
        <LandingHeader
          checkoutPostDetailsValuesApi={checkoutPostDetailsValuesApi}
          productListingValuesApi={productListingValuesApi}
        />
        <CartItemBody
          globalCheckoutDetails={globalCheckoutDetails}
          globalCheckoutCallback={checkoutPostDetailsValuesApi}
          setaddRemoveVariantCount={setaddRemoveVariantCount}
          addressData={addressData}
          productListingValuesApi={productListingValuesApi}
          isVariant={isVariant}
          productItems={productItems}
          filterProductItems={filterProductItems}
          getGuestUserApi={getGuestUserApi}
          restStatus={restStatus}
          promoManualCode={promoManualCode}
          loader={loader}
        />
        {globalCheckoutDetails?.item_total > 0 && isMobileLandind == true ? (
          <div className="viewCartContainer">
            <button
              onClick={() => {
                setIsViewCart(true);
              }}
            >
              View Cart
            </button>
          </div>
        ) : null}
      </div>
      {isViewCart ? (
        <Modal
          show={isViewCart}
          onHide={handleCloseFormsPopUp}
          animation={true}
        >
          <Modal.Header closeButton></Modal.Header>
          <CartItems
            globalCheckoutDetails={globalCheckoutDetails}
            globalCheckoutCallback={checkoutPostDetailsValuesApi}
            addRemoveVariantCount={addRemoveVariantCount}
            prodCartVal={prodCartVal}
            productListingValuesApi={productListingValuesApi}
            promoManual={promoManual}
            productItems={productItems}
            restStatus={restStatus}
          />
        </Modal>
      ) : null}
      {!isMobileLandind ? (
        <div className="res-cart-container">
          <CartItems
            globalCheckoutDetails={globalCheckoutDetails}
            globalCheckoutCallback={checkoutPostDetailsValuesApi}
            addRemoveVariantCount={addRemoveVariantCount}
            productListingValuesApi={productListingValuesApi}
            promoManual={promoManual}
            prodCartVal={prodCartVal}
            productItems={productItems}
            restStatus={restStatus}
          />
        </div>
      ) : null}
      <Location showLocation={showLocation} LocationPopUp={LocationPopUp} />
    </div>
  );
}

export default RestaurentHeader;
