import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import DesiEatsImage from "../Landing/Items/DesiEatsImage";
import { logOutApi } from "../../services/Landingservice";
import { useNavigate } from "react-router-dom";
import ToggleMenuButton from "../Landing/Items/ToggleMenuButton";
import LandingHeader from "../Landing/Landing_header/Landingheader";
function ProfileHeader() {
  let navigate = useNavigate();
  const ClearSession = () => {
    sessionStorage.clear();
    // sessionStorage.removeItem()  - removes particular element
  };
  const logOutButtonApi = async () => {
    let postObject = {
      device_id: "",
      device_type: "",
      device_token: "",
      is_deactivate: "0",
    };
    try {
      let LogoutButtonResponse = await logOutApi(postObject);
      if (LogoutButtonResponse.status === 200) {
        navigate("/");
        ClearSession();
        window.location.reload(true);
      }
    } catch (e) {}
  };

  return (
    <>
      <Row className="ProfileHeaderContainer">
        <Col lg="6" md="2" sm="2" xs="3">
          <LandingHeader/>
          </Col>
        {/* <Col lg="2" md="2" sm="2" xs="3">
          <div className="user-container-logout">
            <Button
              className="LogOutbutton_profile mt-3"
              onClick={() => {
                logOutButtonApi();
                ClearSession();
                navigate("/");
              }}
            >
              <p className="LogOutButtonText">Log Out</p>
            </Button>
          </div>
        </Col> */}
      </Row>
    </>
  );
}

export default ProfileHeader;
