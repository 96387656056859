import React from "react";
import CartViewpage from "../../components/Profile/ProfilePage";
import ProfileHeader from "../../components/Profile/ProfileHeader";
import { useLocation } from "react-router-dom";
function CartView() {
  const { state } = useLocation();
  const { addressView, payNowWallet } = state || {};

  return (
    <>
      <ProfileHeader />
      <CartViewpage addressView={addressView} payNowWallet={payNowWallet} />
    </>
  );
}

export default CartView;
