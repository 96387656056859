import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormSelect,
  Modal,
  ModalTitle,
  Row,
} from "react-bootstrap";
import { ModalBody } from "reactstrap";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { FormInput } from "semantic-ui-react";
import {
  rateDineInApi,
  restaurantViewScreenApi,
} from "../../../../services/CartCheckOutServices";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import { getUserType } from "../../../../constants/Utils";
import Forms from "../../../Landing/Form/Forms";

// import StarIcon from '@mui/icons-material/Star';
function RatingsAddPopup({
  ratingData,
  ratingsAddUpData,
  productItems,
  myOrdersReview,
 productListingValuesApi

}) {
  const ratingsAddPopUp = () => {
    ratingsAddUpData(false); //callback function
  };
  const [inputVal, setInputVal] = useState();
  const [showForms, setShowForms] = useState(false);

  const location = useLocation();
  const handleInput = (e) => {
    setInputVal(e.target.value);
  };
  const closeFormsPopUp = (value) => {
    setShowForms(value);
  };
  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [restRatingScreen, setRestRatingScreen] = useState();
  const ratingApiValuesApi = async (data) => {
    let postratingApiObjectValuesObject = {
      restaurant_id: sessionStorage.getItem("restaurantData")
        ? sessionStorage.getItem("restaurantData")
        : null,
      rating: value,
      review: inputVal != "" ? inputVal : "",
      order_id: myOrdersReview?.order_id ? myOrdersReview?.order_id : "",
    };
    if(getUserType()!=2){

    try {
      let dineinValuesResponse = await rateDineInApi(
        postratingApiObjectValuesObject
      );
      if (dineinValuesResponse?.data?.status != 200)
        swal({
          title: "Error!",
          text: dineinValuesResponse?.data?.message,
          type: "Error",
          timer: 2500,
          button: false,
          icon: "error",
          className: "popuptetx",
        });
      restaurantViewScreenValuesApi();
      if (dineinValuesResponse?.data?.status == 200){
        productListingValuesApi()

      }

    } catch (e) {}
  }else{
    setShowForms(true);
    ratingsAddPopUp();


  }
  };

  const restaurantViewScreenValuesApi = async (data) => {
    let postRestaurantViewScreenApiObject = {
      page: 0,
      limit: "1000",
      restaurant_id: sessionStorage.getItem("restaurantData")
        ? sessionStorage.getItem("restaurantData")
        : null,
    };

    try {
      let restaurantViewScreenResponse = await restaurantViewScreenApi(
        postRestaurantViewScreenApiObject
      );
      setRestRatingScreen(restaurantViewScreenResponse?.data?.data);
      if (restaurantViewScreenResponse?.data?.status == 200) {
        setInputVal("");
        if (location.pathname == "/profile") 
        ratingsAddPopUp();
        setInputVal("");
      }
    } catch (e) {}
  };

  useEffect(() => {
    restaurantViewScreenValuesApi();
  }, []);

  return (
    <div>
    <Modal
      className="addReviewsPopUP modal-lg"
      show={ratingData}
      onHide={ratingsAddPopUp}
      animation={true}
    >
      <Row>
        <div className="notification-header">
          <Modal.Title className="tittle">Reviews</Modal.Title>
          <Modal.Header closeButton></Modal.Header>
        </div>
        {/* <ModalTitle className="DeleteAccountPopup">Add Ratings</ModalTitle> */}
      </Row>
      <ModalBody>
        <>
          <div className="RestDataHeader">
            <h4>
              {productItems?.rest_name
                ? productItems?.rest_name
                : myOrdersReview?.rest_name}
            </h4>

            <b>
              {productItems?.res_description
                ? productItems?.res_description
                : myOrdersReview?.res_description}
            </b>
          </div>
          {productItems?.rest_name ? (
            <div className="ScrollBarRatingDiv">
              {restRatingScreen?.map((item, index) => (
                <>
                  <div className="itemRatingRow">
                    <p className="itemReviewName">{item.fullname}</p>
                    <div className="itemRatingContent">
                      <p className="itemRatingDataProduct ">
                        <AiFillStar className="itemRatingstar" />
                        {item.given_rating}/5
                      </p>
                    </div>
                  </div>
                  <div className="itemContentDataDiv">
                    <p className="itemReviewRESTlistPage">{item.review}</p>
                  </div>
                </>
              ))}
            </div>
          ) : null}
        </>
        <div className="RateItemStarDiv">
          <p>Rate Restaurant</p>
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="hover-feedback"
              value={value}
              precision={1}
              getLabelText={getLabelText}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {value !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
          </Box>

          <p>Write A Review</p>
          <textarea
            style={{ resize: "none" }}
            className="form-control ReviewTextBox   mt-4 mb-2 rows-3"
            rows="4"
            placeholder="Write A Review"
            type="text"
            value={inputVal}
            name="ReviewTextBox"
            id="ReviewTextBox"
            onChange={(e) => handleInput(e)}
          />
          <div className="RatingItemsStarDiv">
            <Button
              className="RatingSendButton"
              onClick={() => ratingApiValuesApi()}
            >
              Send
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
   
                <Forms showForms={showForms} closeFormsPopUp={closeFormsPopUp} />

    </div>
  );
}

export default RatingsAddPopup;
