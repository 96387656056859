import { React, useEffect, useState } from "react";
import { RiShoppingBag3Line, RiNotification3Line } from "react-icons/ri";
import { PersonFill } from "react-bootstrap-icons";
import { FiSearch } from "react-icons/fi";
import { AiOutlineHome } from "react-icons/ai";
import Switch from "@mui/material/Switch";
import { Col, Row, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getName, landingFormsData } from "../../../constants/Utils";
import NotificationPopup from "../../Landing/PopUp/NotificationPopup";
import "./CartItemPopupHeader.css";
import { listAllNotificationApi } from "../../../services/Landingservice";
import Forms from "../../Landing/Form/Forms";
function CartItemPopupHeader() {
  let navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
 
  const closeFormsPopUp = (value) => {
    setShowForms(value);
  };

  function handleClickHome() {
    navigate("/home");
  }
  const navigateToProfile = () => {
    navigate("/profile");
  };
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const getNotificationsApi = async () => {
    let getNotificationsObject = {
      // selected_tab: "1",
      page: "0",
      limit: "100",
    };
    try {
      let notificationsApiApiResponse = await listAllNotificationApi(
        getNotificationsObject
      );
      setNotifications(notificationsApiApiResponse.data.data);
    } catch (e) {}
  };
  const [showForms, setShowForms] = useState(false);

  const handleShow = () => {
    getNotificationsApi();
    setShow(true);
  };
  const closePopUp = (value) => {
    setShow(value);
  };
  let userName = getName() ? getName()[0] : null;

  const handleUserClick = () => {
    if (landingFormsData().user_type === 2) {
      setShowForms(true);
    } else {
      navigateToProfile();
    }
  };

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    sessionStorage.setItem("isCutlery", e.target.checked ? "true" : "false");
  };

  useEffect(() => {
    if (sessionStorage.getItem("isCutlery")) {
      setIsChecked(
        sessionStorage.getItem("isCutlery") === "false" ? false : true
      );
    }
  }, []);

  return (
    <>
      {/* <div className="userProfileFilled">
        <PersonFill className="cart_cardbutton" />
      </div>
      <div className="userProfileFilled higlight">
        <u onClick={() => handleUserClick()}>
          <small>{userName}</small>
        </u>
      </div> */}
      {/* <div className="icons-container">
        <div className="icons-style">
          <AiOutlineHome className="all-icon" onClick={handleClickHome} />
        </div>
        <div className="icons-style">
          <FiSearch className="all-icon" onClick={handleClickSearch} />
        </div>
        <div className="icons-style">
          <RiNotification3Line className="all-icon" onClick={handleShow} />
        </div>
        <div className="icons-style">
          <RiShoppingBag3Line className="all-icon" />
        </div>
      </div> */}

      <Card.Title className="your-cart-text">
        <b>Your Cart</b>
      </Card.Title>

      <p className="cart-desc">
        <small>
          Good Food is always cooking! Go ahead,order some Yummy items from the
          Menu.
        </small>
      </p>
      <div className="cutlery-content">
        <b>
          <small>Do You Really Need Cutlery? </small>
          <Switch

            checked={isChecked}
            disabled={window.location.href.includes("/payment")||
            ((sessionStorage.getItem("isDirectLanding")?(JSON.parse(sessionStorage.getItem("isDirectLanding"))
            ? JSON.parse(sessionStorage.getItem("isDirectLanding")) == 1
            : null):null) && 
              (sessionStorage.getItem("userLocation")?Object.keys(JSON.parse(sessionStorage.getItem("userLocation")))
                ?.length == 0 :null))}
              onChange={handleChange}
          />
        </b>
      </div>
      <div className="cutlery-content">
        <small style={{ fontWeight: "500", fontSize: "12px" }}>
          Consider using your own cutlery to reduce plastic use
        </small>
      </div>

      <NotificationPopup
        show={show}
        closePopUp={closePopUp}
        notifications={notifications}
      />
      <Forms showForms={showForms} closeFormsPopUp={closeFormsPopUp} />
    </>
  );
}

export default CartItemPopupHeader;
